import React from 'react'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'
import TCImageButton from 'tcweb-material-components/core/imageButton'

import theme from '../../theme/light/'

const _theme = {
    palette: {
        primary: {
            main: theme.palette.button.contained.primary.background
        }
    }
}

const ImageButton = (props) => (
    <ThemeProvider theme={_theme}>
        <TCImageButton {...props}/>
    </ThemeProvider>
)

export default ImageButton