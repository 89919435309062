import React, { useMemo, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import Tabs from 'tcweb-material-components/core/tabs'
import Tab from 'tcweb-material-components/core/tab'
import { mergeClasses } from 'tcweb-material-components/core/utilities/styles'

import { decodeText } from '../../utilities/helpers'
import LocaleContext from '../context/localeContext'
import useLocation from '../../hooks/useLocation'
import Link from './link'

const useClasses = makeStyles((theme) => ({
    tabsContainer: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    rootScrollable: {
        margin: 0
    },
    scrollable: {
        borderBottom: `none`,
    },
    tab: {
        display: 'inline-flex',
        minHeight: theme.spacing(7),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            minHeight: theme.spacing(9),
            minWidth: '120px',
        },
        width: 'auto'
    }
}))

const PageChildNavigation = ({ items, isDraft }) => {
    const classes = useClasses()
    const { locale } = useContext(LocaleContext)
    const { location } = useLocation()
    const replaceRegex = new RegExp(`${locale}|\\/`, 'g')
    const initialIndex = useMemo(
        () => (isDraft ? [0] : [items.findIndex((item) => item.uri.replace(/\//g, '') === location.pathname.replace(replaceRegex, ''))]),
        [location, isDraft]
    )

    const LinkTab = (props) => (
        <Tab {...props} classes={mergeClasses({ root: classnames(classes.tab) }, props.classes)} label={props.label} component={Link} />
    )

    return (
        <>
            <div className={classes.tabsContainer}>
                <Tabs
                    classes={{ root: classes.rootScrollable, scrollable: classes.scrollable }}
                    value={Math.max(0, initialIndex[0])}
                    indicatorColor='primary'
                >
                    {items.map((item, i) => {
                        return <LinkTab key={i} value={i} to={item.uri} label={decodeText(item.title)} />
                    })}
                </Tabs>
            </div>
        </>
    )
}

export default PageChildNavigation
