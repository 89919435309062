import React from 'react'
import { makeStyles } from '@material-ui/styles'
import find from 'tcweb-material-components/core/poly/find'

import Link from '../../components/common/link'
import ImageButton from '../../components/common/imageButton'

const useClasses = makeStyles({
	root: { maxWidth: 'initial' }
})

const ImageButtonSwap = ({ children, attribs }) => {
	const image = find(child => child.name === 'img', children)
	const _classes = useClasses()

	return (
		<ImageButton
			component={Link}
			href={attribs.href}
			target={attribs.target}
			rel={attribs.rel}
			src={image.attribs.src}
			srcSet={image.attribs.srcset}
			alt={image.attribs.alt}
			width={image.attribs.width}
			height={image.attribs.height}
			className={_classes.root}
		/>
	)
}

export default ImageButtonSwap
