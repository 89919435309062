import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet'
import count from 'tcweb-material-components/core/poly/count'
import get from 'tcweb-material-components/core/poly/get'
import Box from 'tcweb-material-components/core/box'
import orderBy from 'lodash/orderBy'
import { makeStyles } from '@material-ui/core/styles'

import PageControls from '../components/common/pageControls'
import PageChildNavigation from '../components/common/pageChildNavigation'
import TableOfContents from '../components/common/tableOfContents'
import LayoutContent from '../components/layout/layoutContent'
import Page from '../components/templates/page'
import options from './components/'
import { decodeText } from '../utilities/helpers'
import SEO from '../components/seo/seo'
// import { defaultWPMLLocale } from '../utilities/locale'

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    pageWrapper: { width: '100%' } // For Firefox
})

const getChildPageSettings = (page) => {
    const currentPageHasNav = get(page, 'childTabs.hasChildPageNavigation')
    const parentPageHasNav = get(page, 'wpParent.node.childTabs.hasChildPageNavigation')

    const currentPageHasParentLink = get(page, 'childTabs.pageNavIncludeParentLink')
    const parentPageHasParentLink = get(page, 'wpParent.node.childTabs.pageNavIncludeParentLink')

    const currentPageParentLinkLabel = get(page, 'childTabs.pageNavParentLinkLabel')
    const parentPageParentLinkLabel = get(page, 'wpParent.node.childTabs.pageNavParentLinkLabel')

    // [hasChildNav, includeParentLink, parentLinkLabel]
    return [
        currentPageHasNav || parentPageHasNav,
        currentPageHasParentLink || parentPageHasParentLink,
        (currentPageParentLinkLabel || parentPageParentLinkLabel || '').trim()
    ]
}

const PageTemplate = ({ isDraft, ...props }) => {
    const _classes = useClasses()
    const { data } = props
    const translation = get(data, 'page.wpml_translations', []).find((t) => t.locale === props.pageContext.cmsLocale)
    const isTranslation = !props.pageContext.isFallback && !!translation
    const title = isTranslation ? translation.title : data.page.title
    const parentTitle = get(data, 'page.parent_element.title')
    const content = isTranslation ? translation.content : data.page.content
    const excerpt = isTranslation ? translation.excerpt : get(props, 'data.page.excerpt', '') // No longer supported in WP v6
    const hasChildNav = get(data, 'page.acf.has_child_page_navigation') || get(data, 'page.parent_element.acf.has_child_page_navigation')
    const includeParentLink = get(data, 'page.acf.page_nav_include_parent_link') || get(data, 'page.parent_element.acf.page_nav_include_parent_link')
    const parentLinkLabel = (
        get(data, 'page.acf.page_nav_parent_link_label') ||
        get(data, 'page.parent_element.acf.page_nav_parent_link_label') ||
        ''
    ).trim()

    // Featured image
    const hasFeaturedImage = get(data, 'page.featuredImage')

    // TOC
    const hasToc = get(data, 'page.acf.hasTableOfContents')
    const tocItems = orderBy(get(props, 'pageContext.tableOfContents.nodes'), ['order', 'date'], ['asc', 'asc'])
    const numTocItems = count(tocItems)
    const showToc = hasToc && numTocItems > 0
    const pageControls = get(props, 'pageContext.pageControls', {})

    // Child Pages
    const isChildPage = !!get(props, 'data.page.wpParent.node')
    const childPages = isChildPage
        ? orderBy(get(props, 'data.page.wpParent.node.wpChildren.nodes'), ['menuOrder', 'date'], ['asc', 'asc'])
        : orderBy(get(props, 'data.page.wpChildren.nodes'), ['menuOrder', 'date'], ['asc', 'asc'])
    const showChildPages = hasChildNav && count(childPages) > 1

    if (showToc && !includeParentLink) {
        tocItems.shift()
    } else if (showToc && parentLinkLabel.length) {
        tocItems[0].title = parentLinkLabel
    }

    // Add parent link as first link in navbar
    if (includeParentLink) {
        childPages.unshift({
            title: parentLinkLabel,
            uri: parentPath ? parentPath : path
        })
    }

    const cleanTitle = parentTitle
        ? `${data.site.siteMetadata.title} | ${decodeText(parentTitle)} | ${decodeText(data.page.title)}`
        : `${data.site.siteMetadata.title} | ${decodeText(data.page.title)}`
    const cleanExcerpt = (!!excerpt && decodeText(excerpt)) || ''

    return (
        <>
            <SEO
                metaTitle={title}
                metaCleanTitle={cleanTitle}
                metaDescription={cleanExcerpt}
                metaImage={
                    hasFeaturedImage &&
                    `${data.site.siteMetadata.siteUrl}${data.page.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`
                }
                metaImageAlt={
                    hasFeaturedImage &&
                    data.page.featuredImage.node.altText
                }
                path={data.page.uri}
            />
            <LayoutContent {...props} mainClass='wp-content' setBackgroundImage={showToc}>
                {showChildPages && <PageChildNavigation items={childPages} isDraft={isDraft} />}
                {showToc ? (
                    <Box className={_classes.root}>
                        <div className={_classes.pageWrapper}>
                            <Page meta={data.page.acf} setBackgroundImage={false}>
                                {parse(content, options)}
                            </Page>
                            <Box gutters='page'>
                                <PageControls
                                    {...pageControls}
                                    marginTop={{ xs: '30px', md: '50px' }}
                                    marginBottom={{ xs: '30px', md: '100px' }}
                                    enableTotal={false}
                                    ariaLabelNext='Go to next page'
                                    ariaLabelPrev='Go to previous page'
                                />
                            </Box>
                        </div>
                        <TableOfContents items={tocItems} isDraft={isDraft} />
                    </Box>
                ) : (
                    <Page meta={data.page.acf}>{parse(content, options)}</Page>
                )}
            </LayoutContent>
        </>
    )
}

export const pageQuery = graphql`
    query ($id: String) {
        page: wpSitePage(id: { eq: $id }) {
            title
            content
            slug
            uri
            wpParent {
                node {
                    ... on WPSitePage {
                        uri
                        title
                        # childTabs {
                        #     hasChildPageNavigation
                        #     pageNavIncludeParentLink
                        #     pageNavParentLinkLabel
                        # }
                        wpChildren {
                            nodes {
                                ... on WPSitePage {
                                    title
                                    uri
                                    menuOrder
                                }
                            }
                        }
                    }
                }
            }
            wpChildren {
                nodes {
                    ... on WPSitePage {
                        title
                        uri
                        menuOrder
                    }
                }
            }
            # excerpt # No longer supported in WP v6
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            # translated {
            #     title
            #     content
            #     locale { locale }
            #     toc {  hasTableOfContents }
            #     childTabs {
            #         hasChildPageNavigation
            #         pageNavIncludeParentLink
            #         pageNavParentLinkLabel
            #     }
            #     wpParent {
            #         node {
            #             ... on WPSitePage {
            #                 childTabs {
            #                     hasChildPageNavigation
            #                     pageNavIncludeParentLink
            #                     pageNavParentLinkLabel
            #                 }
            #             }
            #         }
            #     }
            # }
        }
        site {
            siteMetadata {
                title
                socialAlt
                siteUrl
            }
        }
    }
`

export default PageTemplate
