import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from 'tcweb-material-components/core/box'

import Link from './link'

const useClasses = makeStyles(({ palette, accessibility: { highContrast } }) => ({
    button: {
        display: 'flex',
        alignContent: 'center',
        ['&:hover .page-controls-icon, &:focus .page-controls-icon']: {
            backgroundColor: palette.button.contained.primary.hover.background,
            [highContrast.mediaQuery('active')]:{
                border: '2px solid Highlight',
                backgroundColor: 'buttonFace'
            }
        }
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        height: 50,
        width: 50,
        backgroundColor: palette.button.contained.primary.background,
        color: palette.button.contained.primary.color,
        [highContrast.mediaQuery('active')]:{
            backgroundColor: 'buttonFace',
            color: 'buttonText',
        }
    },
    rotate: { transition: 'transform 200ms', transform: 'rotate(180deg)' }
}))

const PageControls = ({ next, prev, ariaLabelPrev, ariaLabelNext, ...rest }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
    const _classes = useClasses()

    if (!next && !prev) return null

    return (
        <Box {...rest}>

            <Box display='flex' justifyContent='space-between'>
                <div>
                    {prev &&
                        <Link href={prev.path || `/${prev.slug}`} className={_classes.button} aria-label={ariaLabelPrev || 'Go to previous article'}>
                            <div className={`page-controls-icon ${_classes.icon}`} style={{ 'marginRight': 10 }}>
                                <FontAwesomeIcon icon={['tcs', 'arrow-right']} className={_classes.rotate} />
                            </div>
                            {!isMobile && 'Previous'}
                        </Link>
                    }
                </div>
                <div>
                    {next &&
                        <Link href={next.path || `/${next.slug}`} className={_classes.button} aria-label={ariaLabelNext || 'Go to next article'}>
                            {!isMobile && 'Next'}
                            <div className={`page-controls-icon ${_classes.icon}`} style={{ 'marginLeft': 10 }}>
                                <FontAwesomeIcon icon={['tcs', 'arrow-right']} />
                            </div>
                        </Link>
                    }
                </div>
            </Box>
        </Box>
    )
}

export default PageControls
