import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'
import Image from 'tcweb-material-components/core/image'

import Link from '../../components/common/link'
import ImageButton from '../../components/common/imageButton'

const ICON_SIZE = '50px'
const ICON_SIZE_DESKTOP = '70px'

const useStyles = makeStyles( ({ breakpoints, palette }) => ({
    image: { width: '100%' },
    svg: {
        position: 'absolute',
        width: ICON_SIZE,
        height: ICON_SIZE,
        top: `calc(50% - (${ICON_SIZE} / 2))`,
        left: `calc(50% - (${ICON_SIZE} / 2))`,
        fill: palette.button.contained.primary.background,
        [breakpoints.up('sm')]: {
            width: '70px',
            height: '70px',
            top: `calc(50% - (${ICON_SIZE_DESKTOP} / 2))`,
            left: `calc(50% - (${ICON_SIZE_DESKTOP} / 2))`,
        }
    },
    figcaption: {
        textAlign: 'center',
        marginTop: '0.5em',
        marginBottom: '1em'
    }
}))

const WatchIconSwap = ({ attribs, children }) => {
    const classes = useStyles()
    const { palette: { button }} = useTheme()
    const anchor = find(child => child.name === 'a', children)
    const image = find(child => child.name === 'img', anchor.children)
    const figcaption = find(child => child.name === 'figcaption', children) || {}
    const text = find(child => child.type === 'text', figcaption.children) || {}

    return (
        <figure className={attribs.class}>
            <Link
                component={ImageButton}
                href={anchor.attribs.href}
                target={anchor.attribs.target}
                rel={anchor.attribs.rel}
                title={get(image, 'attribs.title', text.data)}
            >
                <div className='image-wrapper'>
                    <Image
                        src={image.attribs.src}
                        alt={image.attribs.alt}
                        srcSet={image.attribs.srcset}
                        classes={{ root: classes.image }}
                    />
                </div>
                <svg
                    aria-label='click to watch'
                    className={classes.svg}
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    x='0px'
                    y='0px'
                    viewBox='0 0 192 192'
                >
                    <path d='M96,0C43,0,0,43,0,96s43,96,96,96s96-43,96-96S149,0,96,0z' />
                    <path d='M67.2,134.4V57.6L134.4,96L67.2,134.4z' fill={button.contained.primary.color} />
                </svg>
            </Link>
            <figcaption className={classes.figcaption}>{text.data}</figcaption>
        </figure>
    )
}

export default WatchIconSwap
